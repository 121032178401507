import React from 'react';
import { motion } from 'framer-motion';
import Linkwrapper from "../../linkWrapper/linkWrapper"

export const Button = ({ link, children, ...props }) => {

    //tailwind CSS
    const BtnStyle = "flex w-full md:w-3/4 absolute -translate-y-32 bottom-0 justify-center tracking-widest2 text-xs py-4 z-10 text-white font-Gilroy_extrabold uppercase translate-y-5 px-6"
    const BorderStyle = "absolute overflow-hidden w-full h-full z-0 top-0 left-0 bg-btn-green rounded-full border-grey border-b-4"

    const SubmitButtonStyle = "flex relative subpixel-antialiased mb-0 md:mb-8 focus:outline-none outline-none rounded-full text-xs text-white uppercase font-Gilroy_extrabold tracking-widest py-3 px-8"

    const buttonAnims = {
        tap: {
            scale: 1,
            backgroundColor: "#319795",
            transition: {
                duration: 0.5,
                type: 'spring',
                damping: 3,
                mass: 0.2,
                stiffness: 150,
            }
        },
        hover: {
            scale: 1.1,
            backgroundColor: "#319795",
            transition: {
                duration: 0.5,
                type: 'spring',
                damping: 3,
                mass: 0.2,
                stiffness: 150,
            }
        },
    };

    const buttonTapAreaAnims = {
        tap: {
            scale: [0, 2],
            opacity: [0.05, 0.1, 0],
            transition: {
                duration: 0.5,
                type: 'tween',
            }
        },
        hover: {
            scale: [0, 2],
            opacity: [0.05, 0.1, 0],
            transition: {
                duration: 0.5,
                type: 'tween',
            }
        },
    };

    return (
        <>
            {link !== undefined ?
                <Linkwrapper mouseIcon="link">
                    <a href={link} name="lien" className="w-full" rel="noopener noreferrer" target="_blank">
                        <motion.div
                            {...props}
                            className={BtnStyle}
                        >
                            <div className="relative z-10 pointer-events-none">{children}</div>
                            <motion.div
                                whileTap="tap"
                                whileHover="hover"
                                variants={buttonAnims}
                                className={BorderStyle}
                            >
                                <div className="absolute w-full h-full flex items-center justify-center">
                                    <motion.div
                                        style={{ scale: 0, opacity: 0 }}
                                        variants={buttonTapAreaAnims}
                                        className="z-10 rounded-full bottom-0 h-40 w-40 bg-black opacity-25"></motion.div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </a>
                </Linkwrapper>
                :
                <motion.button
                    className={SubmitButtonStyle}
                    type="submit"
                >
                    <div className="relative z-10 pointer-events-none">{children}</div>
                    <motion.div
                        whileTap="tap"
                        whileHover="hover"
                        variants={buttonAnims}
                        className={BorderStyle}
                    >
                       <Linkwrapper mouseIcon="ok">
                        <div className="absolute w-full h-full flex items-center justify-center">
                            <motion.div
                                style={{ scale: 0, opacity: 0 }}
                                variants={buttonTapAreaAnims}
                                className="z-10 rounded-full bottom-0 h-20 w-20 bg-black opacity-25"></motion.div>
                        </div>
                        </Linkwrapper>
                    </motion.div>
                </motion.button>
            }
        </>
    )
}

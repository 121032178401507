import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { WritingObserver } from "../components/animations/writing";
import BackgroundImage from 'gatsby-background-image'
import { graphql } from "gatsby"
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../components/UI/button/button';
import Smoothscroll from "../components/Smoothscroll/smoothscroll";
import FooterLayout from "../components/footer/footer";
import SEO from "../components/seo"
import {Helmet} from "react-helmet";

function encode(dataSubmit) {
    return Object.keys(dataSubmit)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(dataSubmit[key]))
      .join('&')
}

const ContactFormPage = ({ data }) => {
    const [state, setState] = React.useState({})
    const [validate, setValidate] = React.useState(false)
    const recaptchaKey = "6LfVp5YiAAAAAH47tHyE_sAxOEZJfFUuT_RBGYSH"

    const handleChange = e => setState({ ...state, [e.target.name]: e.target.value })

    // CSS styling Tailwind
    const LabelStyle = "mb-0 lg:mb-5"
    const TitleStyle = "text-xs uppercase font-Gilroy_extrabold text-black tracking-widest mb-5"
    const InputStyle = "p-2 px-4 outline-none border-0 border-b-2 border-black focus:text-tactileGreen focus:border-tactileGreen text-2xl lg:text-lg text-black font-Gilroy_ExtraBoldItalic w-full"
    const ErrorMessage = "py-2 text-red-600 font-Gilroy_medium text-xs h-5"
    const InputMessageStyle = "outline-none focus:text-tactileGreen focus:border-tactileGreen resize-none border-b-2 border-black text-lg text-black font-Gilroy_medium w-full h-32 p-2"
    const SubmitButtonStyle = "mb-8 focus:outline-none outline-none border-grey border-b-4 rounded-full text-xs  text-white uppercase font-Gilroy_extrabold tracking-widest py-2 px-8 bg-btn-green"

    const { register, errors, handleSubmit } = useForm();

    const onSubmit = (dataForm, e) => {
        e.preventDefault()
        let myForm = document.getElementById("form-contact")
        let formData = new FormData(myForm)
        //formData.append('g-recaptcha', recaptchaKey)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                // 'form-name': form.getAttribute('name'),
                'form-name': 'contact',
                'g-recaptcha': recaptchaKey,
                'g-recaptcha-response': formData.get('g-recaptcha-response'),
                ...state,
            }),
        }).then(
            setValidate(true)
        )
    }

    const errorAnim = {
        initial: {
            opacity: 0,
            x: -10
        },
        enter: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
        exit: {
            opacity: 0,
            x: 0,
            transition: {
                duration: 0.25,
                type: 'spring',
                damping: 10,
                mass: 1.5,
                stiffness: 150,
            },
        },
    };

    const formAnim = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.25,
                type: 'tween',
            },
        },
    };

    const dataImg = data;

    return (
        <>
            <SEO title="contact" />
            <Helmet>
                <script src="https://www.google.com/recaptcha/api.js" async defer />
            </Helmet>
            <div id="wrapperContentContact" className="w-full flex justify-center items-center bg-white">
                <Smoothscroll className="mt-20 lg:mt-40 w-full flex items-center justify-center border-b-2 z-20 flex-col">
                    <section className="flex relative flex-row xxl:flex-col items-center justify-center lg:h-screen w-full mt-10 lg:mt-0 xxl:mt-40 z-10 min-height-1080">
                        <div className="w-full lg:w-3/4 lg:mb-20 lg:relative lg:h-screen flex flex-col lg:flex-row items-center gap-10 lg:gap-40 justify-center z-10">
                            <div className="absolute right-0 top-0 mr-10 mt-20 lg:-mt-24 xxl::-mt-32 xxl:-mt-32 z-20 w-3/4 lg:w-1/2 xxl:w-1/2 pointer-events-none">
                                <h1 className="text-right lg:text-left font-Gilroy_ExtraBoldItalic stroke text-5xl md:text-8xl lg:text-6xl xxl:text-8xl leading-none break-normal">Une histoire<br></br> à nous</h1>
                                <div className="w-3/4 ml-20 lg:ml-0 -mt-4 lg:-mt-8">
                                    <WritingObserver isReset={false} delay="500" text="raconter" />
                                </div>
                            </div>
                            <div className="flex items-start justify-start lg:items-center h-full lg:justify-end w-full lg:w-1/2">
                                <div className="w-2/5 lg:w-2/3 h-screen-1/3 lg:h-full right-0">
                                    <BackgroundImage
                                        Tag="section"
                                        className="w-full h-full top-0 z-0 absolute"
                                        style={{ backgroundPosition: "20% 50%" }}
                                        fluid={dataImg.contactImgPage.childImageSharp.fluid}
                                        alt="maison"
                                        backgroundColor={`#136F63`}
                                    />
                                </div>
                            </div>
                            <section className="flex items-center min-h-1/2 lg:h-full w-3/4 lg:w-1/2 relative lg:mb-5">
                                { validate &&
                                    <motion.div animate={{ opacity:1 }} className="flex-col mb-20 lg:mb-0 flex items-center justify-center lg:justify-left opacity-0 absolute w-full lg:w-1/2 h-auto">
                                        <div className="font-Gilroy_ExtraBoldItalic stroke text-4xl lg:text-5xl xl:text-6xl leading-none break-normal" >Merci</div>
                                        <div className="-mt-5 w-1/3"><WritingObserver text="envoyer"/></div>
                                    </motion.div>
                                }
                                <form
                                    id={"form-contact"}
                                    name="contact"
                                    className="w-full lg:mt-48"
                                    method="POST"
                                    data-netlify="true"
                                    onSubmit={handleSubmit(onSubmit)}
                                    action="/"
                                    data-netlify-recaptcha="true"
                                >
                                    <input type="hidden" name="form-name" value="contact" />
                                    <p className="hidden">
                                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                                    </p>
                                    <AnimatePresence>
                                        { validate === false &&
                                            <motion.div
                                                initial="initial"
                                                animate="enter"
                                                exit="exit"
                                                variants={formAnim}
                                            >
                                                <label >
                                                    <div className={LabelStyle}>
                                                        <p className={TitleStyle}>Nom</p>
                                                        <input className={InputStyle} type="text" name="nameInput" onChange={handleChange} ref={register({ required: true })} />
                                                        <AnimatePresence>
                                                            <div className="h-5">
                                                                { errors.nameInput &&
                                                                <motion.p
                                                                    initial="initial"
                                                                    animate="enter"
                                                                    exit="exit"
                                                                    variants={errorAnim}
                                                                    className={ErrorMessage} >
                                                                    ✘ Champ requis
                                                                </motion.p>
                                                                }
                                                            </div>
                                                        </AnimatePresence>
                                                    </div>
                                                </label>
                                                <label>
                                                    <div className={LabelStyle}>
                                                        <p className={TitleStyle}>Email</p>
                                                        <input className={InputStyle} type="email" name="email" onChange={handleChange} ref={register({ required: true })} />
                                                        <AnimatePresence>
                                                            <div className="h-5">
                                                                { errors.email &&
                                                                <motion.p
                                                                    initial="initial"
                                                                    animate="enter"
                                                                    exit="exit"
                                                                    variants={errorAnim}
                                                                    className={ErrorMessage} >
                                                                    ✘ Champ requis
                                                                </motion.p>
                                                                }
                                                            </div>
                                                        </AnimatePresence>
                                                    </div>
                                                </label>
                                                <label>
                                                    <div className={LabelStyle}>
                                                        <p className={TitleStyle}>Telephone</p>
                                                        <input className={InputStyle} maxLength="10" type="tel" onChange={handleChange} name="telephone" ref={register({ required: true })} />
                                                        <AnimatePresence>
                                                            <div className="h-5">
                                                                { errors.telephone &&
                                                                <motion.p
                                                                    initial="initial"
                                                                    animate="enter"
                                                                    exit="exit"
                                                                    variants={errorAnim}
                                                                    className={ErrorMessage} >
                                                                    ✘ Champ requis
                                                                </motion.p>
                                                                }
                                                            </div>
                                                        </AnimatePresence>
                                                    </div>
                                                </label>
                                                <label>
                                                    <div className={LabelStyle}>
                                                        <p className={TitleStyle}>Message</p>
                                                        <textarea className={InputMessageStyle} name="message" onChange={handleChange} maxLength="350" ref={register({ required: true })}></textarea>
                                                        <AnimatePresence>
                                                            <div className="h-5">
                                                                {errors.message &&
                                                                    <motion.p
                                                                        initial="initial"
                                                                        animate="enter"
                                                                        exit="exit"
                                                                        variants={errorAnim}
                                                                        className={ErrorMessage} >
                                                                        ✘ Champ requis
                                                                    </motion.p>
                                                                }
                                                            </div>
                                                        </AnimatePresence>
                                                    </div>
                                                </label>
                                                <div>
                                                    <div className="g-recaptcha flex mb-10" data-sitekey={recaptchaKey}></div>
                                                </div>
                                                <Button>Envoyer</Button>
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </form>
                            </section>
                        </div>
                        <div className="bg-gray-fill absolute z-0 bottom-0 h-13 lg:h-30 w-full"></div>
                    </section>
                    <FooterLayout />
                </Smoothscroll>
            </div>
        </>
    )
}

export default ContactFormPage

export const contactPageQuery = graphql`
  query ContactPage {
    contactImgPage: file(relativePath: { eq: "content/agence/maison.jpg" }) {
      childImageSharp {
        fluid(quality: 98, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
     }
    }
`

import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { isBrowser } from "react-device-detect";
import { useDispatch } from 'react-redux';

const Smoothscroll = ({ scrollHeight, children, isHome, FilterTags, ...props }) => {

    const refscroll = useRef();
    //const [windowHeightState, setWindowHeightState] = useState(0)
    //const { scrollYProgress } = useViewportScroll();
    const [elementHeight, setElementHeight] = useState(null);
    const [navigatorState, setNavigatorState] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        const windowGlobal = typeof window !== 'undefined' && window
        const navigatorGlobal = typeof navigator !== 'undefined' && navigator

        function setValues() {
            setElementHeight(refscroll.clientHeight);
            //setWindowHeightState(windowGlobal.innerHeight);
            dispatch({ type: 'SetScrollValue', value: refscroll.clientHeight });
        };
        setNavigatorState(navigatorGlobal);
        windowGlobal.addEventListener('resize', setValues);
        windowGlobal.setTimeout(() => setValues(), 100);

        return () => windowGlobal.removeEventListener('resize', setValues);
    }, [elementHeight, refscroll, navigatorState, isBrowser, FilterTags]);

    let elementHeightValue = elementHeight;
    //let BlocSpace = isBrowser ? 13 : 7;
    let navigatorGlob = navigatorState;
    //let beginrange = isHome ? -(windowHeightState*BlocSpace) : 0;
    //let midrange = isHome ? -(windowHeightState*BlocSpace) : 0;
    //let midnumber = isHome ? 0.91 : 0;
    //let midrangeOpacity = isHome ? 0.8 : 0;
    //let opacityValueRange = isHome ? 0 : 1;
    //const yvalueDesktopHome = useTransform(scrollYProgress, [0,midnumber, 1], [beginrange,midrange, -elementHeightValue + windowHeightState]);
    //const opcityValueDesktopHome = useTransform(scrollYProgress, [0,midrangeOpacity, 0.9], [opacityValueRange,opacityValueRange,1]);
    //const yspringDesktopHome = useSpring(yvalueDesktopHome, { damping: 15, stiffness: 10, mass: 0.2 })
    // const yspringDesktop = useSpring(yvalueDesktop, { damping: 15, stiffness: 10, mass: 0.2 })

    let isIOS = navigatorGlob !== undefined && (/iPad|iPhone|iPod/.test(navigatorGlob.platform) || (navigatorGlob.platform === 'MacIntel' && navigatorGlob.maxTouchPoints > 1))

    return (
        <>
        <div ref={refscroll} className={`w-full checkMobile z-20`}>
                    <motion.section
                        transition={{ duration: 0.1 }}
                        style={{ y: (isBrowser && true) ? 0 : 0 , opacity: 1}}
                        id="container_scroll"
                        {...props}
                    >
                        {children}
                    </motion.section>
             </div>
            <div id="scrollable" style={{ top: '0px', left: '0px', width: '1px', visibility: 'hidden', height: isBrowser && isIOS===false && elementHeightValue!==null ? 0 : 0 }}></div>
        </>
    )
}

export default connect(state => ({
    scrollHeight: state.Scrollvalue.scrollHeight,
    FilterTags: state.FilterTags.tags,
}), null)(Smoothscroll);
